*{
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: visible;
  cursor: url("./components/images/icons8-cursor.svg"), auto;  
}

.border-black {
  border: 1px solid red;
}

.background {
  background-image: url(./components/images/plant.jpg);
  background-size: cover;
  background-position: center;
}

.icon {
  color: #1a1a1a;
  transition: 0.5s;
}

.icon:hover {
  color: #acacac;
}


body {
  margin-left: 50px;
  margin-right: 50px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Moon';
  src: url('./fonts/moon.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mis';
  src: url('./fonts/Misologist.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: none;
}

.centered-text {
  text-align: center;
  background: none;
  margin-top: -400px;
}

.title {
  font-size: 160px;
  background: none;
  font-family: Mis;
  transition: 0.5s;
}



.supporting {
  font-family: Mis;
  transition: 0.5s ease-out;
}

.supporting-2 {
  font-family: Mis;
  transition: 0.5s ease-out;
}



.full {
  height: 100vh;
}

.svg{
  text-decoration: none;
  color: #1a1a1a;
  margin: 20px;
  transition: 0.5s;
}

.svg:hover{
  transform: translateY(-10px);
  fill: rgb(178, 178, 178);
}

.custom-btn {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  font-size: 15px;
  font-family: Mis;
  background: none;
  border-radius: 100px;
  transition: 0.5s;
  color: rgb(17, 17, 17);
  border: 1px solid black;
}

.custom-btn:hover {
  font-size: 20px;
}

.change-font {
  font-size: 25px;
}



.regular-bg {
  background-color: #e7e8e5;
}

.title-2 {
  font-size: 100px;
  background: none;
  font-family: Mis;
  transition: 0.5s;
}

.seperator {
  margin-top: 100px;
}

.left-sep{
  margin-left: 50px;
}

.para-text {
  margin-right: 50px;
}

.custom {
  font-size: 50px;
  font-family: Mis;
  margin-top: -30px;
}

.title-3 {
  font-size: 50px;
  background: none;
  font-family: Mis;
}

.right-border {
  border-right: 1px solid rgb(52, 52, 52);
}

.mb-3 {
  margin-left: 30px;
  margin-right: 30px;
}

.bottom-border {
  border-bottom: 1px solid rgb(52, 52, 52) ;
}



@media (max-width: 768px) {
 
 .title{
  font-size: 80px;
 }


  .title-2 {
    margin-top: 20px;
    font-size: 50px;
  }

  .title-3{

    font-size: 40px;
  }

  
  .supporting{
    margin-top: 80px;
  }

  .supporting:hover{
    font-size: 20px;
  }

  .port{
    margin-right: 100px;
  }

  #portrait-img {
    margin-top: 30px;
    margin-left: 30px;
    max-width: 290px;
  }

}



@media (max-width: 430px) {

  .change-font{
    font-size: 18px;
  }

}


/* Keyframes for fade-in and slide-down */
@keyframes fadeInSlideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation to your text elements */
.centered-text h5, 
.centered-text h2, 
.centered-text button {
  opacity: 0;
  animation: fadeInSlideDown 1s ease-out forwards;
}

.description {
  opacity: 0;
  animation: fadeInSlideDown 1s ease-out forwards;
}

/* Stagger the animations */
.centered-text h5 {
  animation-delay: 0.2s;
}

.centered-text h2 {
  animation-delay: 0.4s;
}

.centered-text button {
  animation-delay: 0.6s;
}

/* Animation for the About section */
.about-section {
  opacity: 0;
  animation: fadeInSlideDown 1s ease-out forwards;
}

.about-section.animate {
  opacity: 1;
  transform: translateY(0);
}


/* Optional: Add some additional styling */
.custom-btn {
  margin-top: 10px;
}

#symbol {
  background-color: #acacac;
  border: #acacac;
  font-size: 20px;
  transition: 0.5s;
}

#symbol:hover{
  background-color: #1a1a1a;
}

#symbol-text{
  font-size: 30px;
  font-family: Mis;
}

#icon-gap {
  margin-left: 10px;
}


.box {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
  perspective: 1000px;

}
